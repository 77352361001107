import {
  Button,
  createTheme,
  defaultVariantColorsResolver,
  Modal,
  parseThemeColor,
  rgba,
} from "@mantine/core"

const variantColorResolver = (input) => {
  const defaultResolvedColors = defaultVariantColorsResolver(input)

  const parsedColor = parseThemeColor({
    color: input.color || input.theme.primaryColor,
    theme: input.theme,
  })

  // Completely override variant
  if (input.variant === "light") {
    return {
      background: rgba("#FFFFFF", 0.1),
      fontWeight: 700,
    }
  }
  return defaultResolvedColors
}

export const theme = createTheme({
  globalStyles: (theme) => ({
    body: {
      backgroundImage: `url(${theme.other.backgroundImageUrl})`,
      backgroundSize: "cover", // You can adjust the background properties as needed
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  }),
  breakpoints: {
    lg: "80em",
    md: "64em",
    sm: "44em",
    xl: "140em",
    xs: "30em",
  },
  colors: {
    brand: [
      "#f5e9ff",
      "#e5cfff",
      "#c59cff",
      "#a666fe",
      "#8a38fc",
      "#791bfc",
      "#710bfd",
      "#6000e2",
      "#5400cb",
      "#4800b2",
    ],
    starlight: [
      "#f2f0ff",
      "#e0dff2",
      "#bfbdde",
      "#9b98ca",
      "#7d79ba",
      "#6a65b0",
      "#605bac",
      "#504c97",
      "#464388",
      "#3b3979",
    ],
  },
  headings: {
    fontFamily: "inherit",
    fontWeight: 700,
    lineHeight: 1.2,
    sizes: {
      h1: {
        fontSize: "3.5rem",
      },
    },
  },

  components: {
    Button: Button.extend({
      styles: {},
    }),
    Modal: Modal.extend({
      styles: (theme) => ({
        modal: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark
              : theme.colors.light,
        },
        title: {
          color: theme.colors.blue[6], // Custom title color
          fontSize: theme.fontSizes.lg, // Custom title font size
        },
      }),
    }),
  },
  defaultRadius: "sm",
  defaultValue: "light",
  getInitialValueInEffect: true,
  activeClassName: "active",
  key: "mantine-color-scheme",
  primaryColor: "starlight",
  primaryShade: 8,
  other: {
    bgGradient:
      "linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1)",
    bgGradientDark:
      "linear-gradient(to right top, #2e1330, #281739, #211c43, #1b204b, #132554, #0f2b5c, #0a3064, #04366b, #003e73, #00467a, #004e80, #005688)",
    white70: rgba(255, 255, 255, 0.7),
    white90: rgba(255, 255, 255, 0.9),
  },

  variantColorResolver: variantColorResolver,
})
