import { ActionIcon } from "@mantine/core"
import { useHotkeys, useLocalStorage } from "@mantine/hooks"
import {
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarRightCollapse,
} from "@tabler/icons-react"

export default function NavbarCollapseToggle() {
  const [collapsed, setCollapsed] = useLocalStorage({
    defaultValue: false,
    key: "navbar-collapsed",
  })

  //const toggleColorScheme = () => setColorScheme((colorScheme === 'dark' ? 'light' : 'dark'));
  useHotkeys([["mod+shift+n", () => setCollapsed(!collapsed)]])

  return (
    <ActionIcon
        color={'white'}
      onClick={() => setCollapsed(!collapsed)}
      size={40}
      title="Collapse menu"
      variant="subtle"
      visibleFrom={"xs"}
    >
      {!collapsed ? (
        <IconLayoutSidebarLeftCollapse size="1.5rem" stroke={1.5} />
      ) : (
        <IconLayoutSidebarRightCollapse size="1.5rem" stroke={1.5} />
      )}
    </ActionIcon>
  )
}
