import { ActionIcon, useMantineColorScheme } from "@mantine/core"
import { useHotkeys } from "@mantine/hooks"
import { IconMoonStars, IconPlus, IconSun } from "@tabler/icons-react"

export default function DarkToggle() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const dark = colorScheme === "dark"
  useHotkeys([["mod+shift+d", () => toggleColorScheme()]])
  return (
    <ActionIcon
        color={'white'}
      onClick={() => toggleColorScheme()}
      color="white"
      variant="transparent"
      size={45}
    >
      {dark ? <IconSun stroke={1} /> : <IconMoonStars stroke={1} />}
    </ActionIcon>
  )
}
