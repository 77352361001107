import { Box, Group, Stack, Text, useMantineColorScheme } from "@mantine/core"

export const LayoutNavbarMenuItem = ({
  active,
  children,
  collapsed,
  description,
  icon,
  label,
  onClick,
}) => {
  const { colorScheme } = useMantineColorScheme()
  const highlightColor = colorScheme === "light" ? "#3ea5f28f" : "#414141"
  const hoverColor = "#dedede"

  return !collapsed ? (
    <Group
      gap={"10px"}
      onClick={onClick}
      p={"9px"}
      style={{
        "&:hover": {
          backgroundColor: hoverColor,
        },
        alignItems: "center",
        backgroundColor: active ? highlightColor : "initial",
        cursor: "pointer",
        verticalAlign: "middle",
      }}
    >
      {icon && (
        <div style={{ maxHeight: "25px", maxWidth: "25px" }}>{icon}</div>
      )}
      <Stack
        gap={"1px"}
        spacing={0}
        style={{
          flex: 1,
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {children || label}

        {description && (
          <Text maw={"225px"} size={"10px"}>
            {description}
          </Text>
        )}
      </Stack>
    </Group>
  ) : (
    <>
      {icon && (
        <Box
          align={"center"}
          h={"40px"}
          onClick={onClick}
          p={"5px"}
          radius={"4px"}
          style={{
            backgroundColor: active ? highlightColor : "initial",
            borderColor: highlightColor,
            cursor: "pointer",
          }}
          w={"100%"}
          withBorder={true}
        >
          {icon}
        </Box>
      )}
    </>
  )
}
